<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="7"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-input-group style="height: 38px;">
                <v-select
                  v-model="PersonSelected"
                  :options="Persons"
                  label="Name"
                  input-id="payment-method"
                  class="invoice-filter-select"
                  placeholder="Select staff"
                  :clearable="false"
                />
                <b-input-group-append style="height: 37px; margin-left: -5px;">
                  <b-button
                    variant="primary"
                    @click="refresh()"
                  >
                    <feather-icon
                      icon="RefreshCcwIcon"
                      class="mr-50"
                    />
                  </b-button>
                  <!-- <b-button
                    variant="primary"
                  >
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Export</span>
                  </b-button> -->
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="5"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group style="height: 38px;">
              <flat-pickr
                v-model="rangeDate"
                class="form-control mb-1"
                :config="{ mode: 'range', dateFormat: 'Y-m-d' }"
              />
              <b-input-group-append style="height: 38px;">
                <b-button
                  variant="outline-primary"
                  class="fs-8"
                  size="sm"
                  @click="updateData('this_bi_week')"
                >
                  This Bi Week
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="fs-8"
                  size="sm"
                  @click="updateData('last_bi_week')"
                >
                  Last Bi Week
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableProfiles"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      style="position: relative !important;"
    >
      <!-- Column: AccountNumber -->
      <template #cell(day)="data">
        <b-link
          class="font-weight-bold text-nowrap"
        >
          # {{ data.value }}
        </b-link>
      </template>

      <!-- Column: Patient -->
      <template #cell(day_month)="data">
        <span class="text-nowrap font-weight-bold styleboldblack">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: First Check -->
      <template #cell(InStart)="data">
        <div v-if="data.value === ''">
          <span class="text-nowrap font-weight-bold">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            --:--
          </span>
        </div>
        <div v-else>
          <span class="text-nowrap font-weight-bold styleboldblack">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            {{ data.value }}
          </span>
        </div>
      </template>

      <template #cell(OutStart)="data">
        <div v-if="data.value === ''">
          <span class="text-nowrap font-weight-bold">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            --:--
          </span>
        </div>
        <div v-else>
          <span class="text-nowrap font-weight-bold styleboldblack">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            {{ data.value }}
          </span>
        </div>
      </template>

      <!-- Column: Second Check -->
      <template #cell(InEnd)="data">
        <div v-if="data.value === ''">
          <span class="text-nowrap font-weight-bold">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            --:--
          </span>
        </div>
        <div v-else>
          <span class="text-nowrap font-weight-bold styleboldblack">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            {{ data.value }}
          </span>
        </div>
      </template>

      <template #cell(OutEnd)="data">
        <div v-if="data.value === ''">
          <span class="text-nowrap font-weight-bold">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            --:--
          </span>
        </div>
        <div v-else>
          <span class="text-nowrap font-weight-bold styleboldblack">
            <feather-icon
              icon="ClockIcon"
              class="mr-50"
            />
            {{ data.value }}
          </span>
        </div>
      </template>

    </b-table>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink, VBToggle, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import timeSheetService from '@core/services/timeSheets/timeSheets'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BTable,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    flatPickr,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      fields: [{ key: 'day', label: 'Day', sortable: true }, { key: 'day_month', label: 'Month Day', sortable: true }, { key: 'InStart', label: 'Clock In', sortable: true }, { key: 'OutStart', label: 'Break Start', sortable: true }, { key: 'InEnd', label: 'Break End', sortable: true }, { key: 'OutEnd', label: 'Clock Out', sortable: true }],
      items: [],
      Persons: [],
      PersonSelected: [],
      PersonId: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      permission: true,
      rangeDate: null,
      date1: '',
      date2: '',
    }
  },
  watch: {
    PersonSelected(val) {
      if (val === null) {
        this.PersonId = 0
      } else {
        this.PersonId = val.Id
      }
      this.getTimePayroll()
    },
    /* eslint-disable */
    rangeDate(val) {
      if (val.split('to').length > 1) {
        const date = val.split('to')
        this.date1 = moment(date[0].trim()).format('YYYY-MM-DD')
        this.date2 = moment(date[1].trim()).format('YYYY-MM-DD')
        this.getTimePayroll()
      }
    },
    /* eslint-enable */
  },
  created() {
    this.getAllUsers()
    this.getTimePayroll()
    this.updateData('this_bi_week')
  },
  methods: {
    /* eslint-disable */
    getAllUsers() {
      timeSheetService.getAllUsers().then(response => {
        this.Persons = response.data.data
        this.PersonSelected = this.Persons[0]
      })
    },

    refresh(){
      this.getTimePayroll()
    },
    /* eslint-enable */
    getTimePayroll() {
      timeSheetService.getPayrollIndividual({
        initialDate: this.date1,
        finalDate: this.date2,
        idUser: this.PersonId,
      }).then(response => {
        if (Object.keys(response.data.data).length !== 0) {
          this.items = response.data.data
        } else {
          this.items = []
        }
      })
    },
    updateData(time) {
      const thisBiWeekstartDate = moment().date() < 15 ? moment().startOf('month').format('YYYY-MM-DD') : moment(moment().format('YYYY-MM-16')).format('YYYY-MM-DD')
      const thisBiWeekendDate = moment().date() < 15 ? moment(moment().format('YYYY-MM-15')).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD')
      const lastBiWeekstartDate = moment().date() > 15 ? moment().subtract(16, 'days').startOf('month').format('YYYY-MM-DD') : moment(moment().subtract(16, 'days').format('YYYY-MM-16')).format('YYYY-MM-DD')
      const lastBiWeekendDate = moment().date() > 15 ? moment(moment().subtract(16, 'days').format('YYYY-MM-15')).format('YYYY-MM-DD') : moment().subtract(16, 'days').endOf('month').format('YYYY-MM-DD')
      switch (time) {
        case 'this_bi_week':
          this.rangeDate = `${thisBiWeekstartDate} to ${thisBiWeekendDate}`
          break
        case 'last_bi_week':
          this.rangeDate = `${lastBiWeekstartDate} to ${lastBiWeekendDate}`
          break
        default:
      }
    },
  },
}
</script>

<style>
.vs__selected{
    width: 100% !important;
}
</style>

<style lang="scss" scoped>

.invoice-filter-select {
  min-width: 280px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.styleboldblack {
    font-weight: bold;
    color: black;
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
